<template>
  <div class="faq giac">
    <div class="faqz">
      <h2>FAQ</h2>
      <p class="title">FAQ</p>
      <div class="intro">
        <div class="title_ts title_ts1">
          <div></div>
          <h1>购票说明</h1>
        </div>
        <p>
          1、由票务支持单位全程为您提供咨询和服务
          <br />2、票务支持单位：麦思博（北京）软件技术有限公司
          <br />3、对公账户：91240154800002818
          <br />
        </p>
        <div class="title_ts">
          <div></div>
          <h1>参会流程</h1>
        </div>
        <p>
          1、登录官网会员中心（www.msup.com.cn/site/login）
          <br />2、查看已购，激活门票 <br />3、于{{
            meetTime
          }}持电子门票到现场即签到可获得入场凭证 <br />4、进入会场听课
        </p>
        <div class="title_ts">
          <div></div>
          <h1>支付方式</h1>
        </div>
        <p>
          1、在线支付：支付宝，微信
          <br />2、如您需要使用其他方式支付，请联系：{{global.companyphone}}
        </p>
        <div class="title_ts">
          <div></div>
          <h1>发票开具流程说明</h1>
        </div>
        <p>
          1、确认订单支付成功；
          <br />2、工作人员与购票方联系人及时通过邮件沟通发票信息，请购票方确认发票信息100%无误；
          <br />3、在无外力因素的情况下，发票将在7个工作日开具并快递；
          <br />4、会务组规定，在收到购票方票款后，方可按照实际总金额开具，如您有特殊需求，可及时找主办方沟通；
          <br />
        </p>
        <div class="title_ts">
          <div></div>
          <h1>退票说明</h1>
        </div>
        <p>
          1、如您在购票后因故不能参加大会，请至少提前7个工作日将门票转让他人（转让门票请联系本次活动的主办方进行票务信息
          <br />更改，会议开始前7个工作日内不接受转让门票。）
          <br />2、电子门票是大会现场换取入场证的唯一凭证，请妥善保管。
          <br />3、如果门票遗失，登录链接查看。（www.msup.com.cn/site/login）
          <br />4、遇到极端情况会议取消，主办方责任仅限于退回已支付的票款。
        </p>
      </div>
      <a class="phone">如果有任何问题，请联系:{{global.companyphone}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meetTime: String,
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .faq {
    width: 100%;
    .faqz {
      padding: 60px 25px 75px 25px;
      max-width: 1200px;
      margin: 0 auto;
      h2 {
        font-size: 36px;
        font-weight: 500;
        color: #2b2b2b;
        margin: 0;
        text-align: left;
      }
      .title {
        white-space: nowrap;
        margin: 15px 0 0;
        font-size: 18px;
        font-weight: 500;
        color: #c1c1c1;
        text-align: left;
        &:before {
          content: "";
          width: 57px;
          display: block;
          padding-bottom: 15px;
          border-top: 3px solid var(--main-color);
        }
      }
      a {
        color: #131313;
        font-size: 18px;
        margin-top: 70px;
        box-sizing: border-box;
        font-weight: bold;
        display: inline-block;
      }
      .intro {
        width: 100%;
        background-size: 100%;
        margin-top: 45px;
        box-sizing: border-box;
        background-color: #ffffff;
        padding: 15px 50px 25px 40px;
        border-left: 8px solid var(--main-color);
        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          margin-top: 10px;
          color: #999999;
        }
        .title_ts {
          display: flex;
          margin-top: 30px;
          &.title_ts1 {
            margin-top: 0;
          }
          h1 {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            color: #131313;
          }
        }
      }
      .phone {
        width: 100%;
        text-align: left;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .faq {
    padding: 60px 6vw 100px;
    .faqz {
      text-align: left;
      h2 {
        font-size: 24px;
        font-weight: 800;
        color: #2b2b2b;
        margin: 0;
      }
      .title {
        white-space: nowrap;
        margin: 15px 0 0;
        font-size: 12px;
        color: #c1c1c1;
        font-weight: 800;
        &::before {
          content: "";
          width: 57px;
          display: block;
          padding-bottom: 15px;
          border-top: 3px solid var(--main-color);
        }
      }
      .intro {
        border-left: 8px solid var(--main-color);
        margin-bottom: 20px;
        padding: 0 2vw 0 4vw;
        .title_ts {
          margin-top: 20px;
          padding-bottom: 10px;
        }
        p {
          line-height: 22px;
          color: #999;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .phone {
        padding: 0 2vw 0 4vw;
        color: #131313;
        font-size: 15px;
        font-weight: 800
      }
    }
  }
}
</style>